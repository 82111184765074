import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2e5909d1"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = {
  key: 3
};
const _hoisted_5 = {
  key: 4
};
const _hoisted_6 = {
  class: "my-label"
};
const _hoisted_7 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
export default {
  __name: 'dialog-paymentInfo',

  setup(__props, {
    expose: __expose
  }) {
    const size = ref('default');
    const orderInfo = ref();
    const DialogVisible = ref(false);

    const showModal = data => {
      orderInfo.value = data;
      DialogVisible.value = true;
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

      const _component_el_descriptions = _resolveComponent("el-descriptions");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => DialogVisible.value = $event),
        title: "费用明细",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => DialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_descriptions, {
          class: "margin-top",
          column: 1,
          size: size.value,
          border: ""
        }, {
          default: _withCtx(() => [orderInfo.value.ygqx_cos ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 0,
            label: "上门清洗费用",
            "label-class-name": "my-label"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.ygqx_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.ygjx_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 1,
            label: "上门检修费用"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.ygjx_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.ybfz_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 2,
            label: "上门防治费用"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.ybfz_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.fgzj_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 3,
            label: "上门造景费用"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.fgzj_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.ygby_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 4,
            label: "上门搬移费用"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.ygby_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.qtfw_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 5,
            label: "包年服务费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.qtfw_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.discounts ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 6,
            label: "优惠信息"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.discounts), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.fw_distance ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 7,
            label: "上门服务直线距离(km)",
            "label-class-name": "my-label"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.fw_distance), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.ygby_distance ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 8,
            label: "鱼缸搬移直线距离(km)"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.ygby_distance), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.price ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 9,
            label: "总价"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.price), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.refund ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 10,
            label: "退款金额"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.refund), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.refund ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 11,
            label: "退款金额"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.refund), 1)]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["size"]), _createVNode(_component_el_divider), _createVNode(_component_el_descriptions, {
          class: "margin-top",
          column: 1,
          size: size.value,
          border: ""
        }, {
          default: _withCtx(() => [orderInfo.value.trade_uuid ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 0,
            label: "交易号",
            "label-class-name": "my-label"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.trade_uuid), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.pay_status ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 1,
            label: "支付状态"
          }, {
            default: _withCtx(() => [orderInfo.value.pay_status == 1 ? (_openBlock(), _createElementBlock("text", _hoisted_1, " 未付款")) : _createCommentVNode("", true), orderInfo.value.pay_status == 2 ? (_openBlock(), _createElementBlock("text", _hoisted_2, "已付款")) : _createCommentVNode("", true), orderInfo.value.pay_status == 3 ? (_openBlock(), _createElementBlock("text", _hoisted_3, "已退款")) : _createCommentVNode("", true), orderInfo.value.pay_status == 4 ? (_openBlock(), _createElementBlock("text", _hoisted_4, "已付款")) : _createCommentVNode("", true), orderInfo.value.pay_status == 5 ? (_openBlock(), _createElementBlock("text", _hoisted_5, "已付款")) : _createCommentVNode("", true)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.paydownAt ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 2,
            label: "付款时间"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.paydownAt), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.outTradeNo ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 3,
            label: "退款单号"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_6, _toDisplayString(orderInfo.value.outTradeNo), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.refundAt ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 4,
            label: "退款时间"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.refundAt), 1)]),
            _: 1
          })) : _createCommentVNode("", true), orderInfo.value.refundChannel ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 5,
            label: "退款路径"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(orderInfo.value.refundChannel), 1)]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["size"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};