import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import { reactive, ref } from 'vue'; // import { AllDict } from '@/utils/common'

import { editAfterSales } from '@/api/afterSales';
import { ElMessage } from 'element-plus';
const formLabelWidth = '100px';
export default {
  __name: 'modal-info',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const formModel = reactive({
      afterSaleId: 9,
      trade_uuid: null,
      userId: null,
      reason: null,
      text: null,
      photoUrls: null,
      returnTrackingPhone: null,
      returnTrackingNumber: null,
      node: null,
      state: null,
      stateInfo: null,
      orderStatus: null
    });

    const showModal = data => {
      Object.assign(formModel, data);
      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    }; // const title = computed(() => {
    //   return `师傅编号: ${formModel.afterSaleId}`
    // })


    const editOrderFn = async () => {
      const resData = await editAfterSales(formModel);

      if (resData && resData.status === 'success') {
        ElMessage({
          message: '编辑成功！',
          type: 'success'
        });
        emit('refresh');
        hideModal();
      }
    };

    const submitForm = async formEl => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          editOrderFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_image = _resolveComponent("el-image");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => dialogVisible.value = $event),
        title: "编辑师傅信息",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_4, [_createVNode(_component_el_button, {
          onClick: _cache[5] || (_cache[5] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("基本信息")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "师傅姓名",
                prop: "trade_uuid",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.trade_uuid,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.trade_uuid = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "师傅编号",
                prop: "userId",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "input",
                  modelValue: formModel.userId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.userId = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "擅长技能",
                prop: "reason",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.reason,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.reason = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "师傅照片",
                prop: "reason",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [!formModel.photoUrls ? (_openBlock(), _createElementBlock("span", _hoisted_2, "-")) : (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formModel.photoUrls.split(','), (item, i) => {
                  return _openBlock(), _createBlock(_component_el_image, {
                    key: i,
                    style: {
                      "width": "100px",
                      "height": "100px"
                    },
                    src: item,
                    "preview-src-list": formModel.photoUrls ? formModel.photoUrls.split(',') : [],
                    fit: "cover",
                    "preview-teleported": true
                  }, null, 8, ["src", "preview-src-list"]);
                }), 128))]))])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("联系方式")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "所在门店",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.returnTrackingNumber,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.returnTrackingNumber = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "联系电话",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.returnTrackingPhone,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.returnTrackingPhone = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};