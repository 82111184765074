import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "cell-item"
};
const _hoisted_2 = {
  class: "my-label"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
export default {
  __name: 'dialog-againPayMentInfo',

  setup(__props, {
    expose: __expose
  }) {
    const orderInfo = ref();
    const numbuerName = ref(1);
    const nowrecords = ref(); // 存放当前的records

    const goodInfo = ref(); // 存放当前的goods

    const nowGoodsInfo = ref(); // 存放当前的goods

    const DialogVisible = ref(false);

    const showModal = data => {
      orderInfo.value = data;
      numbuerName.value = data.yearly_used;

      if (data.services === '包年服务') {
        nowrecords.value = data.records[data.yearly_used - 1]; // 处理商品

        goodInfo.value = data === null || data === void 0 ? void 0 : data.goodsInfo;
        disGoodsList(data.yearly_used - 1);
      } else {
        nowrecords.value = data.records[0];
        nowGoodsInfo.value = data === null || data === void 0 ? void 0 : data.goodsInfo;
      }

      DialogVisible.value = true;
    }; // 数字按钮


    const numberClick = (tab, event) => {
      nowrecords.value = orderInfo.value.records[tab.index]; // 处理商品

      disGoodsList(tab.index);
    }; // 获取当前的商品列表


    const disGoodsList = index => {
      console.log(index); // index 比商品内的yearly_number小1

      nowGoodsInfo.value = orderInfo.value.goodsInfo.filter(item => {
        return Number(index) + 1 === item.yearly_number;
      });
      console.log(nowGoodsInfo.value);
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

      const _component_el_descriptions = _resolveComponent("el-descriptions");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: DialogVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => DialogVisible.value = $event),
        title: "尾款明细",
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = $event => DialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [orderInfo.value.services === '包年服务' ? (_openBlock(), _createBlock(_component_el_tabs, {
          key: 0,
          modelValue: numbuerName.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => numbuerName.value = $event),
          type: "card",
          class: "demo-tabs",
          onTabClick: numberClick
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderInfo.value.yearly_frequency, item => {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              key: item,
              label: item,
              name: item,
              disabled: item > orderInfo.value.yearly_used ? true : false
            }, null, 8, ["label", "name", "disabled"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])) : _createCommentVNode("", true), _createVNode(_component_el_descriptions, {
          class: "margin-top",
          column: 1,
          size: _ctx.size,
          border: "",
          title: "商品"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nowGoodsInfo.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_descriptions_item, {
              key: index
            }, {
              label: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(item.subject), 1)]),
              default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(item.sortPrice), 1)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }, 8, ["size"]), _createVNode(_component_el_divider), nowrecords.value ? (_openBlock(), _createBlock(_component_el_descriptions, {
          key: 1,
          class: "margin-top",
          column: 1,
          size: _ctx.size,
          title: "费用",
          border: ""
        }, {
          default: _withCtx(() => [nowrecords.value.kp_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 0,
            label: "空跑费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.kp_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.cj_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 1,
            label: "超距费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.cj_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.ygs_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 2,
            label: "原水费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.ygs_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.records ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 3,
            label: "超员费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.cy_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.ltby_cost ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 4,
            label: "楼梯搬运费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.ltby_cost), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.expense ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 5,
            label: "工费"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.expense), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.discount ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 6,
            label: "优惠",
            "label-class-name": "my-label"
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(nowrecords.value.discount), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.final_payment ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 7,
            label: "实付尾款"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.final_payment), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.trade_uuid_final ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 8,
            label: "交易号"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.trade_uuid_final), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.pay_status ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 9,
            label: "支付状态"
          }, {
            default: _withCtx(() => [nowrecords.value.pay_status == 4 ? (_openBlock(), _createElementBlock("text", _hoisted_3, "未付尾款")) : _createCommentVNode("", true), nowrecords.value.pay_status == 5 ? (_openBlock(), _createElementBlock("text", _hoisted_4, "已付尾款")) : _createCommentVNode("", true)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.final_payment_At ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 10,
            label: "付款时间"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.final_payment_At), 1)]),
            _: 1
          })) : _createCommentVNode("", true), nowrecords.value.pay_way ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
            key: 11,
            label: "支付方式"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(nowrecords.value.pay_way), 1)]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["size"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};