import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-44c21652"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref } from 'vue';
import { deleteFworder } from '@/api/order';
import { ElMessage } from 'element-plus';
export default {
  __name: 'dislog-deteleFilled',
  emits: ['restSubmit'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const title = ref('');
    const FWOrders = ref();
    const dialogVisible = ref(false);

    const showModal = data => {
      FWOrders.value = [];
      FWOrders.value = data.FWOrders;
      var id = data.FWOrders.join(',');
      title.value = `是否确定删除订单id为 “${id}” 的订单吗`;
      dialogVisible.value = true;
    };

    const $emit = __emit; // 批量删除

    const deteleFilled = () => {
      dialogVisible.value = false;
      const data = {
        FWOrders: FWOrders.value
      };
      deleteFworder(data).then(res => {
        if (res.status === 'success') {
          // 让父组件中心刷新
          $emit('restSubmit');
          ElMessage({
            message: '删除成功',
            type: 'success'
          });
        }
      }).catch(err => {
        console.log(err);
        ElMessage.error('删除失败');
      });
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: title.value,
        "align-center": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: deteleFilled
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createTextVNode(" 删除 ")]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};